import React from "react";
import Portfoliopage from "../components/portfoliopage";

function Portfolio() {
  return (
    <>
      <Portfoliopage />
    </>
  );
}

export default Portfolio;
